import React from "react";
import "./intro.scss";

export default function Intro() {
  return (
    <div className="intro" id="intro">
      <div className="content-container">
        <div className="info">
          <h1>Oguzhan Ozdemir</h1>
          <h2>Head of Development at Limina Games</h2>
          <p>
            I am a game developer with expertise in virtual reality and
            real-time game engine technologies. Educated in Computer Science at
            Sabancı University, I have spearheaded projects in both Unreal
            Engine and Unity, creating immersive VR experiences and interactive
            gameplay mechanics.
            <br />
            <br />
            Notably, I led the development of 'Grill on Wheels,' a VR cooking
            game known for its advanced interactive features and dynamic game
            environments. My background also includes pivotal simulation work,
            integrating real-world AI into gaming scenarios to enhance realism
            and engagement.
          </p>
        </div>
        <div className="skills-container">
          <h3>Skills and Knowledge</h3>
          <div className="skill-column">
            <div className="game-programming">
              <h4>Game Developer</h4>
              <ul>
                <li>
                  Successfully developed and launched two VR titles on the Meta
                  Store and Steam.
                </li>
                <li>
                  Expert in engineering physics-based mechanics to enhance
                  realism and player immersion.
                </li>
                <li>
                  Adept at creating compelling interactive gameplay mechanics.
                </li>
                <li>
                  Efficient in optimizing VR experiences for Oculus Quest 2,
                  balancing performance with high-quality gameplay.
                </li>
              </ul>
            </div>
            <div className="vr-development">
              <h4>Simulation Engineer</h4>
              <ul>
                <li>
                  Designed and executed a high-fidelity simulation using Unreal
                  Engine.
                </li>
                <li>
                  Integrated real bus ADAS systems with the Carla Simulation
                  Environment for comprehensive testing.
                </li>
                <li>
                  Refined complex simulations to test scenarios replicating
                  crowded environments.
                </li>
                <li>
                  Implemented real-life perception and localization stacks into
                  Unreal Engine simulations.
                </li>
              </ul>
            </div>
          </div>
          <div className="skill-column">
            <div className="ai-integration">
              <h4>Unity</h4>
              <ul>
                <li>
                  Four years of hands-on experience with Unity, crafting VR and
                  AR experiences.
                </li>
                <li>
                  Strong command of C# and object-oriented programming for game
                  development.
                </li>
                <li>
                  Specialized in VR performance optimization for seamless
                  gameplay.
                </li>
                <li>
                  Skilled in multiplayer game development using Photon
                  Networking.
                </li>
              </ul>
            </div>
            <div className="Simulation-Engineer">
              <h4>Unreal Engine</h4>
              <ul>
                <li>
                  Over six years of in-depth work with Unreal Engine for game
                  and simulation development.
                </li>
                <li>
                  Proficient in both C++ and Blueprint scripting to craft
                  intricate game logic and features.
                </li>
                <li>
                  Proven track record in creating realistic training simulations
                  for various industries.
                </li>
                <li>
                  Knowledgeable in utilizing Unreal Engine's Python API for
                  advanced game networking solutions.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
