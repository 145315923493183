import React from "react";
import "./Sidebar.scss";
import LogoS from "../assets/me.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faGithub,
  faSpotify,
} from "@fortawesome/free-brands-svg-icons";
import CV from "../assets/OguzhanOzdemirResume.pdf";

export default function Sidebar({ menuOpen }) {
  const handleGamesButtonClick = () => {
    const element = document.getElementById("grill-on-wheels");
    element?.scrollIntoView({ behavior: "smooth" });
  };
  const handleSimulationButtonClick = () => {
    const element = document.getElementById("simulation");
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={"sidebar " + (menuOpen && "active")}>
      <div className="top-section">
        <a href="#intro">
          <img className="logo" src={LogoS} alt="Oguzhan Ozdemir" />
        </a>
      </div>
      <div className="section-links">
        <a
          href="#grill-on-wheels"
          className="section"
          onClick={handleGamesButtonClick}
        >
          Games
        </a>
        <a
          href="#simulation"
          className="section"
          onClick={handleSimulationButtonClick}
        >
          Simulation
        </a>
        <a href={CV} className="section resume">
          Resume
        </a>
        <a
          href="mailto:ossanozfe@gmail.com?subject=Contacting from the Website&body=Hi Oguzhan,"
          className="section contact"
        >
          Contact
        </a>
      </div>
      <div className="iconContainer">
        <a
          target="_blank"
          className="icon"
          rel="noreferrer"
          href="https://www.linkedin.com/in/oguzhan-ozdemir-10b3621a3/"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a
          target="_blank"
          className="icon"
          rel="noreferrer"
          href="https://github.com/oguzhanozfe"
        >
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a
          target="_blank"
          className="icon"
          rel="noreferrer"
          href="https://open.spotify.com/user/oguzhanozdemir-"
        >
          <FontAwesomeIcon icon={faSpotify} />
        </a>
      </div>
    </div>
  );
}
