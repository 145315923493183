import React from "react";
import Sidebar from "./components/Sidebar/Sidebar";
import Intro from "./components/intro/Intro";
import Gow from "./components/GrillOnWheels/GoW";
import MM from "./components/MansionMystery/MM";
import Simulation from "./components/Simulation/Simulation";
import "./app.scss";

function App() {
  return (
    <div className="app">
      <Sidebar />
      <div className="sections">
        <Intro />
        <Gow />
        <MM />
        <Simulation />
      </div>
    </div>
  );
}

export default App;
