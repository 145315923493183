import React from "react";
import "./Simulation.scss";
import CarlaBanner from "../assets/BannerSim.png";
import StudioLogo from "../assets/Adastec.svg";

const Simulation = () => {
  return (
    <div className="simulation-container" id="simulation-engineer">
      <div className="simulation-banner">
        <img
          src={CarlaBanner}
          alt="Simulation Banner"
          className="simulation-banner-image"
        />
      </div>
      <div className="simulation-body">
        <div className="simulation-info">
          <h1>Simulation Engineer</h1>
          <p className="simulation-description">
            I led the development of autonomous driving simulations through
            Unreal Engine in a Linux environment, combining C++ and Python for
            effective AI communication. My key contribution was integrating
            Carla Simulator into Unreal Engine, which significantly increased
            our data processing capability to 72 million points per second for
            point cloud data. This enhancement was crucial for producing
            detailed simulations and managing complex calculations.
            Collaborating closely with perception and localization experts, we
            brought new simulation features to life, leveraging advanced AI
            technology. I was responsible for the careful planning and execution
            of project timelines, guiding diverse team efforts to meet and
            exceed our goals. Central to my role was also enhancing
            cross-departmental communication, ensuring everyone was aligned with
            the strategic direction set by ADASTEC.
          </p>
        </div>
        <div className="simulation-media">
          <div className="simulation-video">
            <iframe
              src="https://www.youtube.com/embed/aFOx2yBpa4c?autoplay=1&loop=1&playlist=aFOx2yBpa4c&mute=1&controls=0&modestbranding=1"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
              allowFullScreen
              title="Simulation Gameplay"
            ></iframe>
          </div>
          <img src={StudioLogo} alt="Studio Logo" className="studio-logo" />
        </div>
      </div>
    </div>
  );
};

export default Simulation;
