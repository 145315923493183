import React from "react";
import "./GoW.scss";
import GowBanner from "../assets/GowBanner.png";
import MetaLogo from "../assets/Meta_Quest_logo.svg";
import SteamLogo from "../assets/steamvr-branding-1.svg";

const GoW = () => {
  return (
    <div className="gow-container" id="grill-on-wheels">
      <div className="gow-banner">
        <img src={GowBanner} alt="Grill on Wheels Banner" className="gow-banner-image" />
      </div>
      <div className="gow-body">
        <div className="gow-info">
          <h1>Grill on Wheels</h1>
          <p className="gow-description">
            Go ahead and stock up on groceries, kickstart your day, and prepare to sell delicious
            burgers, fries, and drinks. Earn chef points, sway foodies with your culinary prowess,
            and share your favorite moments on Trucksgram. Expand your menu by investing in new
            equipment, purchase and customize additional trucks, and if you need an extra hand,
            invite a friend to tackle even tougher challenges.
          </p>
            <h2>Features</h2>
            <ul>
              <li>Dynamic cooking slicing and burger creation</li>
              <li>35+ Recipes</li>
              <li>20+ Upgrades</li>
              <li>5+ Locations</li>
              <li>Customizable Trucks</li>
              <li>Multiplayer Mode</li>
            </ul>
            <h2>Metrics</h2>
            <ul>
              <li>100k+ organic installs</li>
              <li>4.2-star rating</li>
              <li>250+ reviews</li>
              <li>20k+ active users</li>
            </ul>
          </div>
          <div className="project-media">
          <div className="gow-video">
          <iframe
              src="https://www.youtube.com/embed/R03U3UHb8k0?autoplay=1&loop=1&playlist=R03U3UHb8k0&mute=1&controls=0&modestbranding=1"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
              allowFullScreen
              title="Grill on Wheels Gameplay"
            ></iframe>
          </div>
          <div className="gow-platform-logos">
            <a href="https://www.meta.com/experiences/7056585347694088/" target="_blank" rel="noopener noreferrer">
              <img src={MetaLogo} alt="Meta Logo" />
            </a>
            <a href="https://store.steampowered.com/app/2628680/Grill_on_Wheels/" target="_blank" rel="noopener noreferrer">
              <img src={SteamLogo} alt="Steam Logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoW;