import React from "react";
import "./MM.scss";
import MMBanner from "../assets/MMBanner.png";
import MetaLogo from "../assets/Meta_Quest_logo.svg";
import SteamLogo from "../assets/steamvr-branding-1.svg";

const MM = () => {
  return (
    <div className="mm-container" id="mansion-mystery">
      <div className="mm-banner">
        <img
          src={MMBanner}
          alt="Mansion Mystery Banner"
          className="mm-banner-image"
        />
      </div>
      <div className="mm-body">
        <div className="mm-info">
          <h1>Mansion Mystery</h1>
          <p className="mm-description">
            An unforgettable experience awaits in this immersive VR escape room
            game. Find yourself trapped in a mysterious mansion with a series of
            unique challenges to test your puzzle-solving skills. Interact with
            the environment, solve intricate puzzles, and escape the mansion.
            Each puzzle is designed to provide an engaging and challenging
            experience.
          </p>
          <h2>Features</h2>
          <ul>
            <li>25+ unique puzzles</li>
            <li>3 different environments</li>
          </ul>
        </div>
        <div className="mm-media">
          <div className="mm-video">
            <iframe
              src="https://www.youtube.com/embed/fdjhya6iYB4?start=300&autoplay=1&loop=1&playlist=fdjhya6iYB4&mute=1&controls=0&modestbranding=1"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
              allowFullScreen
              title="Mansion Mystery Gameplay"
            ></iframe>
          </div>
          <div className="mm-platform-logos">
            <a
              href="https://www.meta.com/experiences/5691978394241239/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={MetaLogo} alt="Meta Quest Logo" />
            </a>
            <a
              href="https://store.steampowered.com/app/2628680/Mansion_Mystery/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={SteamLogo} alt="Steam Logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MM;
